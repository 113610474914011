import React, { memo, useCallback } from 'react';
import type { Dispatch, SetStateAction, MouseEventHandler } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
// import useForm, { OnSubmit } from '~/lib/useForm';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { auth } from '~/lib/auth';
import { DialogClose } from '~/components/Dialog';
import clsx from 'clsx';
import enter from './corner-down-left.svg';
import './SignIn.css';

type FormInputs = {
  email: string;
};

const preventDefault: MouseEventHandler<HTMLButtonElement> = (e) => e.preventDefault();

interface SignInProps {
  // signIn: OnSubmit;
  setSuccess: Dispatch<SetStateAction<boolean | null>>;
  setLoading: Dispatch<SetStateAction<boolean | null>>;
}

// const formOptions = { resetOnSubmit: true };

export const SignIn: React.FC<SignInProps> = ({ setSuccess, setLoading }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<FormInputs>({ shouldUseNativeValidation: true });
  const onSubmit = useCallback<SubmitHandler<FormInputs>>(
    async ({ email }) => {
      try {
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        await auth.sendOobCode('EMAIL_SIGNIN', email);
        // setLoading(false);
        window.localStorage.setItem('loginEmail', email);
        // The link was successfully sent. Inform the user.
        // Save the email locally, so you don't need to ask the user for it again
        // if they open the link on the same device.
        // return () => {
        setLoading(false);
        setSuccess(true);
        // };
      } catch (error: any) {
        setLoading(false);
        const message =
          error?.message === 'Failed to fetch'
            ? 'There was a network error, please try again.'
            : error?.message;
        setError('email', { type: error.name, message });
        // return { email: error?.message };
      }
    },
    [setError, setLoading, setSuccess]
  );
  // const { values, handleChange, handleSubmit, errors } = useForm(
  //   { onSubmit },
  //   undefined,
  //   formOptions
  // );

  const registerCall = useCallback(
    () =>
      register('email', {
        required: 'Missing email',
        minLength: 3,
        maxLength: 320
      }),
    [register]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="signIn">
        <Input
          registerCall={registerCall}
          name="email"
          type="email"
          placeholder="Email"
          spellCheck={false}
          error={errors.email}
        />
        <DialogClose asChild onClick={preventDefault}>
          <Button
            cta
            type="submit"
            className="signIn__button mouse-only"
            onClick={handleSubmit(onSubmit)}
          >
            <img src={enter} alt="Sign in" />
          </Button>
        </DialogClose>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

export default memo(SignIn);
