import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  fromPromise,
  NormalizedCacheObject,
  makeVar,
  from,
  split
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { tokenRefreshLink } from 'apollo-link-opaque-refresh';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { getAuth, auth } from '~/lib/auth';
import typeDefs from '~/graph/schema';
import getInitialQuery from '~/graph/schema/initialState';

export const loadingFoodInterpVar = makeVar([]);
const networkStatusNotifier = createNetworkStatusNotifier();
export const { useApolloNetworkStatus } = networkStatusNotifier;
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_HASURA_WS_URL as string, // use wss for a secure endpoint
    lazy: true,
    retryAttempts: 15
  })
);

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_HASURA_URL as string,
  fetch: auth.authorizedRequest
});

const retryLink = new RetryLink();

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { extensions } = error;
      switch (extensions?.code) {
        case 'invalid-jwt': {
          // refetch the jwt
          const oldHeaders = operation.getContext().headers;
          const { token, userId } = getAuth();
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${token}`,
              'x-hasura-user-id': userId
            }
          });
          // retry the request, returning the new observable
          return forward(operation);
        }
        default:
          // default case
          console.log(error);
      }
    });
  if (networkError) {
    // console.log(`[Network error]: ${networkError}`);
  }
});

const errorPromoter = new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    if (data && data.errors && data.errors.length > 0) {
      throw new Error('GraphQL Operational Error');
    }
    return data;
  })
);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext((_: any, { headers = {} } = {}) => {
    const { token, userId } = getAuth();
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          'x-hasura-user-id': userId
        }
      };
    }
    return headers;
  });

  return forward(operation);
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const link = from([
  authMiddleware,
  errorPromoter,
  tokenRefreshLink({
    refreshToken: async () => {
      try {
        // Create global custom dom event listener in Auth.tsx
        // Trigger events here
        // MISTAKE - ACTUALLY USE GLOBAL PROMISES created in those Auth.tsx useEffect hooks
        await window.firebaseRefresh?.();
      } catch (error) {
        window.signOut?.();
        throw error;
      }
    },
    shouldRefresh: ({ operation, result, networkError }: any) =>
      // console.log(operation, result, networkError);
      // {
      //   "errors": [
      //   {
      //     "extensions": {
      //       "path": "$",
      //       "code": "invalid-jwt"
      //     },
      //     "message": "Could not verify JWT: JWTExpired"
      //   }
      // ]
      // }
      // console.log('result?.errors');
      // console.log(result?.errors);
      result?.errors?.[0]?.extensions?.code === 'invalid-jwt'
  }),
  retryLink,
  errorLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    // @ts-ignore
    wsLink,
    networkStatusNotifier.link.concat(httpLink)
  )
]);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loadingFoodInterp: {
          read() {
            return loadingFoodInterpVar;
          }
        },
        food_log_raw: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          }
        }
      }
    }
  }
});

export async function setupApollo(): Promise<ApolloClient<NormalizedCacheObject>> {
  cache.writeQuery(getInitialQuery());

  // Continue setting up Apollo as usual.
  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache,
    link,
    name: 'Ziliad',
    version: '1.0',
    typeDefs
    // resolvers,
  });

  client.onResetStore(async () => cache.writeQuery(getInitialQuery()));

  return client;
}
