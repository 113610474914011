import React, { memo } from 'react';

const SunFill = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    strokeWidth="1"
    className="ai ai-SunFill"
  >
    <linearGradient id="lgrad" x1="0%" y1="80%" x2="100%" y2="20%">
      <stop offset="0%" style={{ stopColor: 'rgb(105,124,171)', stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: 'rgb(0,164,173)', stopOpacity: 1 }} />
    </linearGradient>
    <path d="M12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1zM3.293 3.293a1 1 0 0 1 1.414 0l1.5 1.5a1 1 0 0 1-1.414 1.414l-1.5-1.5a1 1 0 0 1 0-1.414zm17.414 0a1 1 0 0 1 0 1.414l-1.5 1.5a1 1 0 1 1-1.414-1.414l1.5-1.5a1 1 0 0 1 1.414 0zM1 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1zm19 0a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1zM6.207 17.793a1 1 0 0 1 0 1.414l-1.5 1.5a1 1 0 0 1-1.414-1.414l1.5-1.5a1 1 0 0 1 1.414 0zm11.586 0a1 1 0 0 1 1.414 0l1.5 1.5a1 1 0 0 1-1.414 1.414l-1.5-1.5a1 1 0 0 1 0-1.414zM12 20a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1z"
    />
    <circle fill="" cx="12" cy="12" r="4" />
  </svg>
);

export default memo(SunFill);
