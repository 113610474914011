import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import AppLayout from '~/layouts/AppLayout';
import Public from '~/layouts/Public';

const Session = React.lazy(() => import('~/components/Session'));
const Careers = React.lazy(() => import('~/components/Careers'));
const MagicLink = React.lazy(() => import('~/components/MagicLink'));
const Terms = React.lazy(() => import('~/components/Terms'));
const Privacy = React.lazy(() => import('~/components/Privacy'));
const Refund = React.lazy(() => import('~/components/Refund'));
const NoMatch = React.lazy(() => import('~/components/NoMatch'));

const Router = () => (
  <Routes>
    <Route path="/" element={<AppLayout />}>
      <Route
        index
        element={
          <Suspense fallback={<>Loading...</>}>
            <Session />
          </Suspense>
        }
      />
      <Route
        path="magic-link"
        element={
          <Suspense fallback={<>Loading...</>}>
            <MagicLink />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<>Loading...</>}>
            <NoMatch />
          </Suspense>
        }
      />
    </Route>

    <Route element={<Public />}>
      <Route
        path="/terms"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Terms />
          </Suspense>
        }
      />
      <Route
        path="/refund"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Refund />
          </Suspense>
        }
      />
      <Route
        path="/privacy"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Privacy />
          </Suspense>
        }
      />
      <Route
        path="/careers"
        element={
          <Suspense fallback={<>Loading...</>}>
            <Careers />
          </Suspense>
        }
      />
    </Route>
  </Routes>
);

export default Router;
