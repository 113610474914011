import React, { memo, useCallback, useState } from 'react';
import Button from '~/components/Button';
import { SignIn } from '~/components/SignIn';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
  DialogClose,
  DialogX
} from '~/components/Dialog';
import './Login.css';
import userPlus from './user-plus.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const Login: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleSuccess = useCallback((isSuccess: any) => {
    if (isSuccess) setOpen(false);
  }, []);
  // const handleOpenChange = useCallback((isOpen) => {
  //   setOpen(isOpen);
  // }, []);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button cta>
          <img src={userPlus} alt="Sign in" className="login__icon" />
        </Button>
      </DialogTrigger>
      <DialogContent className="login__dialog">
        <DialogTitle>Sign in</DialogTitle>
        {/* <DialogX /> */}
        <DialogDescription>
          Save your diet and get more accurate nutrition information with a free Ziliad account.
          <br />
          <br />
          We&apos;ll email you a link to log in.
        </DialogDescription>
        <SignIn setLoading={noop} setSuccess={handleSuccess} />
      </DialogContent>
    </Dialog>
  );
};

export default memo(Login);
