import React, { memo } from 'react';

const Sun = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ai ai-Sun"
  >
    <linearGradient id="lgrad" x1="0%" y1="80%" x2="100%" y2="20%">
      <stop offset="0%" style={{ stopColor: 'rgb(105,124,171)', stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: 'rgb(0,164,173)', stopOpacity: 1 }} />
    </linearGradient>
    <path d="M12 3V2m0 20v-1m9-9h1M2 12h1m15.5-6.5L20 4M4 20l1.5-1.5M4 4l1.5 1.5m13 13L20 20" />
    <circle cx="12" cy="12" r="4" />
  </svg>
);

export default memo(Sun);
