import AuthLite from '~/lib/firebase-auth-lite';
// import { OnSubmit } from '~/lib/useForm';

// Find these options in your Firebase console
const firebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const actionCodeSettings = {
  url:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/magic-link'
      : 'https://ziliad.com/magic-link',
  // This must be true.
  handleCodeInApp: true
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
export const auth = new AuthLite({
  apiKey: firebaseOptions.apiKey,
  redirectUri: actionCodeSettings.url
});

// export const signInWithEmail: OnSubmit = async ({ email }) => {
//   try {
//     window.localStorage.setItem('loginEmail', email);
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//     return await auth.sendOobCode('EMAIL_SIGNIN', email);
//     // The link was successfully sent. Inform the user.
//     // Save the email locally, so you don't need to ask the user for it again
//     // if they open the link on the same device.
//   } catch (error: any) {
//     console.log(error);
//     return { email: error?.message };
//   }
// };

export const handleRedirect = async (email: string) => {
  try {
    await auth.handleSignInRedirect({ email });
    window.localStorage.removeItem('loginEmail');
    window.history.replaceState(null, document.title, '/');

    // Do whatever you want with the newly logged-in user
  } catch (error) {
    console.error(error);
  }
};

export const getAuth = () => ({
  token: window.app.token || localStorage.getItem('token') || '',
  userId: window.app.userId || localStorage.getItem('userId') || ''
});

export const setAuth = (token: string, userId: string) => {
  window.app.token = token;
  window.app.userId = userId;
  localStorage.setItem('token', token);
  localStorage.setItem('userId', userId);
};
