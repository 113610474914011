import { useCallback, useEffect } from 'react';
import { useLocalstorageState, useMediaMatch } from 'rooks';

export type ColorScheme = 'auto' | 'dark' | 'light';

const setAuto = () => {
  document.documentElement.classList.remove('theme-light');
  document.documentElement.classList.remove('theme-dark');
};

const setDark = () => {
  document.documentElement.classList.remove('theme-light');
  document.documentElement.classList.add('theme-dark');
};

const setLight = () => {
  document.documentElement.classList.add('theme-light');
  document.documentElement.classList.remove('theme-dark');
};

const useTheme = () => {
  const deviceDark = useMediaMatch('(prefers-color-scheme: dark)');
  const [darkPreference, setDarkPreference] = useLocalstorageState<ColorScheme>(
    'prefers-color-scheme',
    'auto'
  );

  const darkMode = darkPreference === 'auto' ? deviceDark : darkPreference === 'dark';
  const handlePreferenceChange = useCallback(
    (theme: ColorScheme) => {
      setDarkPreference(theme);
    },
    [setDarkPreference]
  );

  // FIXME: DANG IT WE NEED AUTO | DARK | LIGHT

  useEffect(() => {
    if (darkPreference === 'auto') {
      setAuto();
    } else if (darkPreference === 'dark') {
      setDark();
    } else {
      setLight();
    }
  }, [darkPreference]);

  return { darkMode, handlePreferenceChange, darkPreference };
};

export default useTheme;
