import usePromise from 'react-promise-suspense';
import React, { Suspense, StrictMode } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';
import { setupApollo } from '~/lib/apollo';
import Router from '~/components/Router';
import { FirebaseAuthProvider } from '~/components/FirebaseAuth';
import ApolloWrapper from './ApolloWrapper';
import './App.css';
import './Animation.css';

const history = createBrowserHistory(); // or createHashHistory()
wrapHistory(history);

const App: React.FC = () => (
  <StrictMode>
    <HelmetProvider>
      <HistoryRouter history={history}>
        <Suspense fallback={null}>
          <ApolloWrapper />
        </Suspense>
      </HistoryRouter>
    </HelmetProvider>
  </StrictMode>
);

export default App;
