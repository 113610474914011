import type { LastPhysiologyQuery } from '~/generated/graphql';
import { cmToFtIn, convertWeight, gramsToKg } from '~/lib/conversions';
import { differenceInYears, parseISO } from 'date-fns';

export type ShortPhysiology = (
  physiology: LastPhysiologyQuery,
  prefersFt?: boolean | null,
  prefersLbs?: boolean | null
) => string | undefined;

export const shortPhysiology: ShortPhysiology = (physiology, prefersFt, prefersLbs) => {
  const now = new Date();
  const serverWeight = gramsToKg(physiology?.weight[0]?.value).toString();
  const serverGoalWeight = gramsToKg(physiology?.goal_weight[0]?.value).toString();
  const serverHeight = physiology?.height[0]?.value;
  const serverBirthDate = physiology?.users[0]?.birth_date;
  const serverAge = differenceInYears(now, parseISO(serverBirthDate));

  const ageDisplay = `${serverAge}y`;
  const heightDisplay = prefersFt ? cmToFtIn(serverHeight) : `${serverHeight}cm`;
  const weightDisplay = prefersLbs
    ? `${convertWeight('lbs', serverWeight) || ''}lbs`
    : `${serverWeight}kg`;
  const weightGoalDisplay = prefersLbs
    ? `${convertWeight('lbs', serverGoalWeight) || ''}lbs`
    : `${serverGoalWeight}kg`;

  // eslint-disable-next-line no-irregular-whitespace
  return `${ageDisplay}  |  ${heightDisplay}  |  ${weightDisplay} -> ${weightGoalDisplay}`;
};
