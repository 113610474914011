import { gql } from '@apollo/client';

const schema = gql`
  directive @client on FIELD

  enum AuthStates {
    LOADING
    IN
    OUT
  }

  type Auth {
    status: AuthStates!
  }

  type FirebaseUser {
    id: String
    email: String
  }

  input FirebaseUserInput {
    id: String!
    email: String!
  }

  type Token {
    id: String
  }

  type NetworkStatus {
    isConnected: Boolean!
  }

  extend type query_root {
    auth: Auth
    firebaseUser: FirebaseUser
    token: Token
    networkStatus: NetworkStatus
  }

  extend type mutation_root {
    setAuth(status: AuthStates!): Auth!
    setFirebaseUser(user: FirebaseUserInput!): FirebaseUser!
    setToken(id: String!): Token!
  }
`;

export default schema;
