import { NavLink, Outlet } from 'react-router-dom';
import React from 'react';
import Footer from '~/components/Footer';
import useTheme from '~/lib/useTheme';
import './Public.css';

const Public = () => {
  useTheme();

  return (
    <div className="app appLayout">
      <h1>
        <NavLink to="/">Ziliad</NavLink>
      </h1>
      <Outlet />
      <Footer />
    </div>
  );
};

export default Public;
