/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import clsx from 'clsx';
import './Button.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  primary?: boolean;
  loading?: boolean;
  borderOnly?: boolean;
  cta?: boolean;
  childOnly?: boolean;
  rref?: React.MutableRefObject<any>;
  flippedProps?: any;
}

// TODO: FAST COMPARE TO MEMOIZE FLIPPEDPROPS
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      primary = false,
      cta = false,
      borderOnly = false,
      type = 'button',
      disabled,
      onClick,
      selected = false,
      loading = false,
      rref,
      name,
      flippedProps = undefined,
      title,
      childOnly = false,
      ...rest
    },
    forwardedRef
  ) => (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      name={name}
      className={clsx('button', className, {
        'button--selected': selected,
        'button--showChild': childOnly,
        buttonPrimary: primary,
        buttonTransparent: cta,
        buttonLoading: loading,
        // @ts-ignore
        icon: children?.type === 'img',
        borderOnly
      })}
      onClick={onClick}
      title={title}
      ref={forwardedRef || rref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...flippedProps}
      {...rest}
    >
      {children}
    </button>
  )
);

export default memo(Button);
