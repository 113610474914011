import React from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import usePromise from 'react-promise-suspense';
import { setupApollo } from '~/lib/apollo';
import { FirebaseAuthProvider } from '~/components/FirebaseAuth';
import Router from '~/components/Router';

const ApolloWrapper: React.FC = () => {
  const client: ApolloClient<never> = usePromise(setupApollo, []);

  return (
    <FirebaseAuthProvider>
      <ApolloProvider client={client}>
        <Router />
      </ApolloProvider>
    </FirebaseAuthProvider>
  );
};

export default ApolloWrapper;
