import React, { forwardRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';
import X from '~/components/Icons/x';
import Button from '~/components/Button';
import './Dialog.css';

export const DialogX = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  React.ComponentProps<typeof DialogPrimitive.Close>
>((props, forwardedRef) => {
  // eslint-disable-next-line react/prop-types -- invalid
  const { className, ...itemProps } = props;
  return (
    <DialogPrimitive.Close
      {...itemProps}
      ref={forwardedRef}
      className={clsx('dialog__x', className)}
      asChild
    >
      <Button cta>
        <X width={14} />
      </Button>
    </DialogPrimitive.Close>
  );
});

export const DialogContent = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof DialogPrimitive.Content>
  // eslint-disable-next-line react/prop-types  -- literally wrong
>(({ children, className, ...props }, forwardedRef) => (
  <DialogPrimitive.Portal>
    <DialogPrimitive.Overlay className="dialog__overlay">
      <DialogPrimitive.Content {...props} className={clsx('dialog', className)} ref={forwardedRef}>
        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Overlay>
  </DialogPrimitive.Portal>
));

export const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentProps<typeof DialogPrimitive.Title>
>((props, forwardedRef) => {
  // eslint-disable-next-line react/prop-types -- invalid
  const { className, ...itemProps } = props;
  return (
    <DialogPrimitive.Title
      {...itemProps}
      ref={forwardedRef}
      className={clsx('dialog__title', className)}
    />
  );
});

export const Dialog = DialogPrimitive.Root;
export const DialogClose = DialogPrimitive.Close;
export const DialogDescription = DialogPrimitive.Description;
export const DialogTrigger = DialogPrimitive.Trigger;
