import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { auth } from 'lib/auth';

type User = any | null;
type ContextState = { user: User };

const FirebaseAuthContext = createContext<ContextState | undefined>(undefined);

export const FirebaseAuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>(null);
  const value = useMemo(() => ({ user }), [user]);

  // @ts-ignore
  useEffect(() => auth.listen(setUser), []);

  return <FirebaseAuthContext.Provider value={value}>{children}</FirebaseAuthContext.Provider>;
};

export const useFirebaseAuth = () => {
  const context = useContext(FirebaseAuthContext);
  if (context === undefined) {
    // FIXME: ONLY NOT THROWING FOR TESTS
    // throw new Error('useFirebaseAuth must be used within a FirebaseAuthProvider');
    return {};
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return context.user;
};
