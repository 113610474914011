import React, { memo, Suspense } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Button from '~/components/Button';
import useTheme from '~/lib/useTheme';
import { shortPhysiology } from '~/lib/shortPhysiology';
import { useLastPhysiologyQuery, useSettingsQuery } from '~/generated/graphql';
import SunFill from '~/components/ThemeSwitch/SunFill';
import Sun from '~/components/ThemeSwitch/Sun';
import { auth } from '~/lib/auth';
import { useFirebaseAuth } from '~/components/FirebaseAuth';
import hamburger from './hamburger.svg';
import './Menu.css';

// Preload with lazy
const settingsPromise = import('./Settings');
const Settings = React.lazy(() => settingsPromise);
const historyPromise = import('./History');
const History = React.lazy(() => historyPromise);
const signOutPromise = import('./SignOut');
const SignOut = React.lazy(() => signOutPromise);

const Menu = () => {
  const { darkMode, darkPreference, handlePreferenceChange } = useTheme();
  const { data: physiology } = useLastPhysiologyQuery();
  const { data: settings } = useSettingsQuery();
  const user = useFirebaseAuth();
  const prefersFt = settings?.users?.[0]?.prefers_ft;
  const prefersLbs = settings?.users?.[0]?.prefers_lbs;

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <Button cta>
          <img src={hamburger} alt="Sign in" className="menu__icon" />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content className="menu">
        <DropdownMenu.Arrow className="menu__arrow" />
        <DropdownMenu.Label className="menu__label">
          {user?.email || 'My account'}
        </DropdownMenu.Label>
        <DropdownMenu.Group>
          {physiology ? (
            <DropdownMenu.Item className="menu__item">
              <div className="menu__radioIndicator">
                <Suspense fallback={null}>
                  <Settings />
                </Suspense>
              </div>
              {shortPhysiology(physiology, prefersFt, prefersLbs)}
            </DropdownMenu.Item>
          ) : null}
          <DropdownMenu.Item className="menu__item">
            <div className="menu__radioIndicator">
              <Suspense fallback={null}>
                <History />
              </Suspense>
            </div>
            History
          </DropdownMenu.Item>

          <DropdownMenu.Item />
        </DropdownMenu.Group>

        <DropdownMenu.Separator className="menu__separator" />
        <DropdownMenu.Label className="menu__label">Theme</DropdownMenu.Label>
        <DropdownMenu.RadioGroup
          value={darkPreference}
          onValueChange={handlePreferenceChange as (value: string) => void}
        >
          <DropdownMenu.RadioItem value="auto" className="menu__item">
            <DropdownMenu.ItemIndicator className="menu__radioIndicator">
              {darkMode ? <Sun /> : <SunFill />}
            </DropdownMenu.ItemIndicator>
            Auto
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="light" className="menu__item">
            <DropdownMenu.ItemIndicator className="menu__radioIndicator">
              <SunFill />
            </DropdownMenu.ItemIndicator>
            Light
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="dark" className="menu__item">
            <DropdownMenu.ItemIndicator className="menu__radioIndicator">
              <Sun />
            </DropdownMenu.ItemIndicator>
            Dark
          </DropdownMenu.RadioItem>
        </DropdownMenu.RadioGroup>
        <DropdownMenu.Separator className="menu__separator" />
        <DropdownMenu.Item onClick={auth.signOut} className="menu__item">
          <div className="menu__radioIndicator">
            <Suspense fallback={null}>
              <SignOut />
            </Suspense>
          </div>
          Sign out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default memo(Menu);
