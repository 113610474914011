import { gql } from '@apollo/client';

const getInitialQuery = () => ({
  query: gql`
    query root {
      auth {
        status
      }
      firebaseUser {
        id
        email
      }
      networkStatus {
        isConnected
      }
    }
  `,
  data: {
    auth: { status: 'LOADING' },
    firebaseUser: { id: null, email: null },
    networkStatus: {
      __typename: 'NetworkStatus',
      isConnected: false
    }
  }
});

export default getInitialQuery;
