import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.css';

const Nav: React.FC = () => (
  <nav className="nav">
    <ul className="nav__list">
      <li>
        <NavLink to="/">Home</NavLink>
      </li>
      <li>
        <NavLink to="/account">Account</NavLink>
      </li>
      <li>
        <NavLink to="/contact-us">Contact Us</NavLink>
      </li>
    </ul>
    <ul className="nav__list">
      <li>
        <NavLink to="/careers">Careers</NavLink>
      </li>
      <li>
        <NavLink to="/terms">Terms</NavLink>
      </li>
      <li>
        <NavLink to="/privacy">Privacy</NavLink>
      </li>
    </ul>
  </nav>
);

export default memo(Nav);
