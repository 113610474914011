import React, { memo } from 'react';

interface XProps {
  width?: number;
}

const X: React.FC<XProps> = ({ width = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    stroke="var(--textColor)"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x"
    preserveAspectRatio="xMidYMid meet"
  >
    <path d="M20 20L4 4m16 0L4 20" />
  </svg>
);

export default memo(X);
