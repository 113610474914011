import { NavLink, Outlet } from 'react-router-dom';
import clsx from 'clsx';
import React from 'react';
import { useLocalstorageState, useMediaMatch } from 'rooks';
import Auth from '~/components/Auth';
import Footer from '~/components/Footer';
import { useApolloNetworkStatus } from '~/lib/apollo';
import useTheme from '~/lib/useTheme';
import './AppLayout.css';

const AppLayout = () => {
  const status = useApolloNetworkStatus();
  useTheme();

  return (
    <div className="app appLayout">
      <Auth />
      <h1
        className={clsx({
          'animate--text-shadow-lr': status.numPendingQueries > 0
        })}
      >
        <NavLink to="/">Ziliad</NavLink>
      </h1>
      <Outlet />
      <Footer />
    </div>
  );
};

// TODO: Unrelated to this file. Use <meter> tag for values?

export default AppLayout;
