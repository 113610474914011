import React, { memo } from 'react';
import Nav from '~/components/Nav';
import clsx from 'clsx';
import './Footer.css';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => (
  <footer className={clsx(className, 'footer')}>
    <Nav />
    <p className="footer__paragraph">
      © Ziliad - All content whatsoever contained on or within Ziliad.com is for informational
      purposes only and is not intended to substitute the advice, diagnosis, or treatment from a
      qualified medical professional.
    </p>
  </footer>
);

export default memo(Footer);
