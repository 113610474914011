const poundsPerKg = 2.20462262185;

export const kgToGrams = (kg: number) => Math.round(kg * 1_000);

export const gramsToKg = (g: number) => Math.round(g / 1_000);

export const cmToFtIn = (value: string | number): string => {
  const floatValue = Number.isFinite(value) ? value : parseFloat(value as string);
  if (Number.isNaN(floatValue)) return value.toString();

  let inches = Math.round((floatValue as number) * 0.39370078);
  const feet = Math.floor(inches / 12);
  inches %= 12;

  return `${feet}′${inches}″`;
};

export const convertWeight = (to: string, value?: string | number): string | number | undefined => {
  if (value === undefined || Number.isNaN(value) || value === null) return value;
  let result = 0;
  const floatValue = Number.isFinite(value) ? value : parseFloat(value as string);
  if (Number.isNaN(floatValue)) return value;

  if (to === 'lbs') {
    result = Math.round((floatValue as number) * poundsPerKg);
  } else {
    result = Math.round((floatValue as number) * (1 / poundsPerKg) * 100 + Number.EPSILON) / 100;
  }

  return result.toString();
};
