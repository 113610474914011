// import { useMutation, useApolloClient, useQuery, gql } from '@apollo/client';
import clsx from 'clsx';
import React, { memo } from 'react';
// import { getAuth, getFirebaseUser, getTokenState } from '~/graph/queries';
// import { setAuth, signInWithEmail } from '~/lib/auth';
// import { OnSubmit } from '~/lib/useForm';
// import Button from '~/components/Button';
// import { SignIn } from '~/components/SignIn';
import Login from '~/components/Login';
// import { Route, Routes } from 'react-router-dom';
import { useFirebaseAuth } from '~/components/FirebaseAuth';
import Menu from '~/components/Menu';
import './Auth.css';
// import userIcon from './user.svg';
// import userPlus from './user-plus.svg';
// import userX from './user-x.svg';

// const endpoint = 'https://us-central1-ziliadd.cloudfunctions.net/refreshToken';
// const actionCodeSettings = {
//   url: process.env.DEV ? 'http://localhost:3000' : 'https://ziliad.com',
//   // This must be true.
//   handleCodeInApp: true
// };
// const firebaseRefresh = async () => {
//   const auth = firebaseGetAuth();
//   return auth?.currentUser?.getIdToken(true);
// };
// Find these options in your Firebase console
// const firebaseOptions = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };
//
// const handleRedirect = () => {
//   try {
//     window.localStorage.removeItem('emailForSignIn');
//     window.history.replaceState(null, document.title, '/');
//
//     // Do whatever you want with the newly logged-in user
//   } catch (error) {
//     console.error(error);
//   }
// };

// const createFirebaseApp = (config = {}) => {
//   try {
//     return getApp();
//   } catch (e) {
//     return initializeApp(config);
//   }
// };

// createFirebaseApp(firebaseOptions);

// const resolvers = {
//   Mutation: {
//     setAuth: (_: any, { status }: any, { cache }) => {
//       cache.writeQuery({ query: getAuth, data: { auth: { status } } });
//       return status;
//     },
//     setFirebaseUser: (_: any, { user: { id, email } }: any, { cache }: any) => {
//       cache.writeQuery({ query: getFirebaseUser, data: { firebaseUser: { id, email } } });
//       return { id, email };
//     },
//     setToken: (_: any, { status }: any, { cache }: any) => {
//       cache.writeQuery({ query: getTokenState, data: status });
//       return status;
//     }
//   }
// };

// const MagicLink = () => {
//   useEffect(() => {
//     let email = window.localStorage.getItem('emailForSignIn');
//     if (!email) {
//       // User opened the link on a different device. To prevent session fixation
//       // attacks, ask the user to provide the associated email again. For example:
//       // eslint-disable-next-line no-alert
//       email = window.prompt('Please provide your sign-in email for confirmation');
//     }
//     setPersistence(auth, browserLocalPersistence)
//       .then(() =>
//         // The client SDK will parse the code from the link for you.
//         signInWithEmailLink(auth, email || '', window.location.href).then(({ user }: any) => {
//           // Clear email from storage.
//           window.localStorage.removeItem('emailForSignIn');
//           window.history.replaceState(null, document.title, '/');
//           // You can access the new user via result.user
//           // Additional user info profile not available via:
//           // result.additionalUserInfo.profile == null
//           // You can check if the user is new or existing:
//           // result.additionalUserInfo.isNewUser
//         })
//       )
//       .catch((error: Error) => {
//         // Some error occurred, you can inspect the code: error.code
//         // Common errors could be invalid email and invalid or expired OTPs.
//         console.log(error);
//       });
//   });
//
//   return null;
// };
//
const Auth = () => {
  //   const client = useApolloClient();
  //   client.addResolvers(resolvers);
  //   const [setAuthState] = useMutation(SET_AUTH_STATE);
  //   const [setUserState] = useMutation(SET_USER_STATE);
  //   const {
  //     data: {
  //       auth: { status: authStatus }
  //     }
  //   }: any = useQuery(getAuth);
  //   const firebaseUserData: any = useQuery(getFirebaseUser);
  //   const { email: userEmail, id: userUid } = firebaseUserData?.firebaseUser || {};
  //   const [signInVisible, setSignInVisible] = useState(false);
  //   const [success, setSuccess] = useState<boolean | null>(null);
  //   const [loading, setLoading] = useState<boolean | null>(null);
  //   const [setTokenState] = useMutation(SET_TOKEN_STATE);
  //
  //   useEffect(() => {
  //     const auth = firebaseGetAuth();
  //     if (isSignInWithEmailLink(auth, window.location.href)) {
  //       let email = window.localStorage.getItem('emailForSignIn');
  //       if (!email) {
  //         // User opened the link on a different device. To prevent session fixation
  //         // attacks, ask the user to provide the associated email again. For example:
  //         // eslint-disable-next-line no-alert
  //         email = window.prompt('Please provide your sign-in email for confirmation');
  //       }
  //       setPersistence(auth, browserLocalPersistence)
  //         .then(() =>
  //           // The client SDK will parse the code from the link for you.
  //           signInWithEmailLink(auth, email || '', window.location.href).then(({ user }: any) => {
  //             // Clear email from storage.
  //             window.localStorage.removeItem('emailForSignIn');
  //             window.history.replaceState(null, document.title, '/');
  //             // You can access the new user via result.user
  //             // Additional user info profile not available via:
  //             // result.additionalUserInfo.profile == null
  //             // You can check if the user is new or existing:
  //             // result.additionalUserInfo.isNewUser
  //           })
  //         )
  //         .catch((error: Error) => {
  //           // Some error occurred, you can inspect the code: error.code
  //           // Common errors could be invalid email and invalid or expired OTPs.
  //           console.log(error);
  //         });
  //     }
  //   }, []);
  //   useEffect(() => {
  //     const auth = firebaseGetAuth();
  //     onAuthStateChanged(auth, async (user) => {
  //       if (user) {
  //         const token = await user.getIdToken();
  //         const idTokenResult = await user.getIdTokenResult();
  //         const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];
  //
  //         if (hasuraClaim) {
  //           setAuthState({ variables: { status: 'IN' } });
  //           setUserState({ variables: { user: { email: user.email, id: user.uid } } });
  //           setTokenState({ variables: { id: token } });
  //           setAuth(token, user.uid);
  //         } else {
  //           fetch(`${endpoint}?uid=${user.uid}`)
  //             .then((res) => {
  //               if (res.status === 200) {
  //                 return user.getIdToken(true);
  //               }
  //               return res.json().then((e) => {
  //                 throw e;
  //               });
  //             })
  //             .then((idToken) => {
  //               setAuthState({ variables: { status: 'IN' } });
  //               setUserState({ variables: { user: { email: user.email, id: user.uid } } });
  //               setTokenState({ variables: { id: idToken } });
  //               setAuth(token, idToken);
  //             })
  //             .catch((e) => {
  //               setAuthState({ variables: { status: 'OUT' } });
  //               setUserState({ variables: { user: { email: null, id: null } } });
  //               setTokenState({ variables: { id: null } });
  //               setAuth('', '');
  //             });
  //         }
  //       } else {
  //         // TODO: RESET APOLLO STATE ON LOGOUT
  //         // FIXME: ABOVE
  //         setAuthState({ variables: { status: 'OUT' } });
  //         setUserState({ variables: { user: { email: null, id: null } } });
  //         setTokenState({ variables: { id: null } });
  //         setAuth('', '');
  //       }
  //     });
  //   }, [setAuthState, setTokenState, setUserState]);
  //
  //   const signOut = useCallback(async () => {
  //     try {
  //       setAuthState({ variables: { status: 'LOADING' } });
  //       const auth = firebaseGetAuth();
  //       await firebaseSignOut(auth);
  //       setAuthState({ variables: { status: 'OUT' } });
  //       setAuth('', '');
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, [setAuthState]);
  //
  //   let content;
  //   if (authStatus === 'IN') {
  //     content = (
  //       <Button cta onClick={signOut} className={style.auth__button}>
  //         <img src={userIcon} alt="My account" title={userEmail} className={style.auth__icon} />
  //       </Button>
  //     );
  //   } else if (authStatus === 'OUT') {
  //     content = (
  //       <>
  //         <Button cta onClick={() => setSignInVisible(!signInVisible)} className={style.auth__button}>
  //           <img src={signInVisible ? userX : userPlus} alt="Sign in" className={style.auth__icon} />
  //         </Button>
  //         {signInVisible && (
  //           <SignIn signIn={signInWithEmail} setSuccess={setSuccess} setLoading={setLoading} />
  //         )}
  //         {success === true ? <div>Login link sent</div> : null}
  //       </>
  //     );
  //   } else {
  //     content = null;
  //   }

  // useEffect(() => {
  //   window.signOut = signOut;
  //   window.firebaseRefresh = firebaseRefresh;
  //
  //   return () => {
  //     delete window.signOut;
  //     delete window.firebaseRefresh;
  //   };
  // }, [signOut]);
  //
  const authClass = clsx('auth', {
    // [style.authSignInVisible]: signInVisible
    'auth--signInVisible': true
  });

  const user = useFirebaseAuth();

  return <div className={authClass}>{user?.localId ? <Menu /> : <Login />}</div>;
};
export default memo(Auth);
