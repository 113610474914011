/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import clsx from 'clsx';
import type { UseFormRegisterReturn, FieldError } from 'react-hook-form';
import './Input.css';

const getEmpty = () => ({});

interface InputProps extends React.InputHTMLAttributes<Element> {
  label?: string;
  registerCall?: () => UseFormRegisterReturn;
  error?: FieldError;
  innerRef?: React.RefObject<HTMLInputElement>;
  name: string;
}

const Input: React.FC<InputProps> = ({
  className,
  name,
  registerCall = getEmpty,
  disabled,
  placeholder,
  autoFocus,
  defaultValue,
  maxLength,
  minLength,
  label,
  spellCheck,
  type = 'text',
  error,
  title
}) => (
  <>
    <input
      className={clsx('input', className)}
      type={type}
      id={name}
      placeholder={placeholder}
      /* eslint-disable-next-line jsx-a11y/no-autofocus -- We need this for now */
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      disabled={disabled}
      title={title}
      spellCheck={spellCheck}
      aria-invalid={error ? 'true' : 'false'}
      {...registerCall?.()}
    />
    {label && (
      <label className="input__label" htmlFor={name}>
        {label}
      </label>
    )}
    {error && <span role="alert">{error.message}</span>}
  </>
);

export default memo(Input);
